<template>
  <div id="post-detail" class="post-detail">
    <div class="detail">
      <div class="header">
        <div class="title">{{ postPublish.post }}</div>
        <span>发布时间：{{ postPublish.createTime }}</span>
        <span>来源：辽宁省科学技术馆</span>
      </div>
      <div class="content">
        <div class="title">工作内容</div>
        <div class="body" v-html="postPublish.remark"></div>
      </div>
    </div>
    <div class="btn-group">
      <el-button v-if="btnToResumeEnabled" @click="goResume" type="primary" size="medium">报名</el-button>
      <el-button @click="back" size="medium">返回</el-button>
    </div>
  </div>
</template>

<script>
import {getPostPublished} from '@/api/PostDetail'

export default {
  name: "PostDetail",
  props: ['id'],
  data() {
    return {
      btnToResumeEnabled: false,
      postPublish: {}
    }
  },
  created() {
    // 判断当前登录用户是否已经填报简历
    let resume = sessionStorage.getItem("resume")
    if (resume) {
      this.btnToResumeEnabled = false
    } else {
      this.btnToResumeEnabled = true
    }
    this.getPostPublish()
  },
  methods: {
    getPostPublish() {
      getPostPublished(this.id).then(response => {
        if (response.status === 200) {
          this.postPublish = response.data.data
        }
      })
    },
    goResume() {
      this.$router.push({path: "/user_resume/" + this.id});
    },
    back() {
      history.back()
    }
  }
}
</script>

<style scoped>
#post-detail {
  margin: 0 auto;
  max-width: 1440px;
}

#post-detail > .detail {
  max-width: 1380px;
  background-color: #ffffff;
  margin-top: 32px;
  padding: 0 30px 82px 30px;
}

.btn-group {
  text-align: center;
  margin-top: 48px;
}

#post-detail > .detail > .header {
  text-align: center;
  height: 146px;
  border-bottom: 1px solid #E0E0E0;
}

#post-detail > .detail > .header > .title {
  padding-top: 32px;
  font-size: 30px;
  font-weight: 600;
  color: #333333;
  height: 42px;
  line-height: 42px;
  margin-bottom: 26px;
}

#post-detail > .detail > .header span {
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
  margin: 0 20px
}

#post-detail > .detail > .content {
  margin-top: 30px;
}

#post-detail > .detail > .content > .title {
  font-size: 24px;
  font-weight: 600;
  color: #1677FF;
  line-height: 33px;
  border-left: 4px solid #1677FF;
  padding-left: 8px;
}

#post-detail > .detail > .content > .body {
  margin-top: 20px;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
}
</style>
